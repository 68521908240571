<template>
    <div>
        <div class="mb-3">
            <SectionTopMenu hero="Snapshots" help-header="FlowFuse - Snapshots" info="A list of all snapshots generated by any Instance or Device within this Application.">
                <template #pictogram>
                    <img alt="info" src="../../images/pictograms/snapshot_red.png">
                </template>
                <template #helptext>
                    <p>Snapshots generate a point-in-time backup of your Node-RED flow, credentials and runtime settings.</p>
                    <p>Snapshots are also required for deploying to devices. In the Deployments page of a Project, you can define your “Target Snapshot”, which will then be deployed to all connected devices.</p>
                    <p>You can also generate Snapshots directly from any instance of Node-RED using the <a target="_blank" href="https://github.com/FlowFuse/nr-tools-plugin">FlowFuse NR Tools Plugin.</a></p>
                </template>
            </SectionTopMenu>
        </div>
        <ff-loading v-if="loading" message="Loading Snapshots..." />
        <template v-if="snapshots.length > 0">
            <!-- set mb-14 (~56px) on the form to permit access to kebab actions where hubspot chat covers it -->
            <ff-data-table data-el="snapshots" class="space-y-4 mb-14" :columns="columns" :rows="snapshots" :show-search="true" search-placeholder="Search Snapshots...">
                <template #context-menu="{row}">
                    <ff-list-item :disabled="!hasPermission('snapshot:edit')" label="Edit Snapshot" @click="showEditSnapshotDialog(row)" />
                    <ff-list-item :disabled="!canViewSnapshot(row)" label="View Snapshot" @click="showViewSnapshotDialog(row)" />
                    <ff-list-item :disabled="!canViewSnapshot(row)" label="Compare Snapshot..." @click="showCompareSnapshotDialog(row)" />
                    <ff-list-item :disabled="!canDownload(row)" label="Download Snapshot" @click="showDownloadSnapshotDialog(row)" />
                    <ff-list-item :disabled="!canDownloadPackage(row)" label="Download package.json" @click="downloadSnapshotPackage(row)" />
                    <ff-list-item :disabled="!canDelete(row)" label="Delete Snapshot" kind="danger" @click="showDeleteSnapshotDialog(row)" />
                </template>
            </ff-data-table>
        </template>
        <template v-else-if="!loading">
            <EmptyState>
                <template #img>
                    <img src="../../images/empty-states/instance-snapshots.png">
                </template>
                <template #header>What are Snapshots?</template>
                <template #message>
                    <p>
                        Snapshots are point-in-time backups of your Node-RED Instances & Devices.
                    </p>
                    <p>
                        They capture the flows, credentials and runtime settings, and can
                        be pushed & deployed from one Instance/Device to any other, or
                        used to rollback an Instance to a point in history.
                    </p>
                </template>
            </EmptyState>
        </template>
    </div>
    <SnapshotEditDialog ref="snapshotEditDialog" data-el="dialog-edit-snapshot" @snapshot-updated="onSnapshotEdit" />
    <SnapshotExportDialog ref="snapshotExportDialog" data-el="dialog-export-snapshot" />
    <AssetDetailDialog ref="snapshotViewerDialog" data-el="dialog-view-snapshot" />
    <AssetCompareDialog ref="snapshotCompareDialog" data-el="dialog-compare-snapshot" />
</template>

<script>
import { markRaw } from 'vue'
import { mapState } from 'vuex'

import ApplicationApi from '../../api/application.js'
import SnapshotsApi from '../../api/snapshots.js'

import EmptyState from '../../components/EmptyState.vue'
import SectionTopMenu from '../../components/SectionTopMenu.vue'
import AssetCompareDialog from '../../components/dialogs/AssetCompareDialog.vue'
import AssetDetailDialog from '../../components/dialogs/AssetDetailDialog.vue'
import SnapshotEditDialog from '../../components/dialogs/SnapshotEditDialog.vue'
import UserCell from '../../components/tables/cells/UserCell.vue'
import { downloadData } from '../../composables/Download.js'
import permissionsMixin from '../../mixins/Permissions.js'
import Alerts from '../../services/alerts.js'
import Dialog from '../../services/dialog.js'
import { applySystemUserDetails } from '../../transformers/snapshots.transformer.js'

// Table Cells
import DaysSince from './Snapshots/components/cells/DaysSince.vue'
import SnapshotName from './Snapshots/components/cells/SnapshotName.vue'
import SnapshotSource from './Snapshots/components/cells/SnapshotSource.vue'
import SnapshotExportDialog from './Snapshots/components/dialogs/SnapshotExportDialog.vue'

export default {
    name: 'ApplicationSnapshots',
    components: {
        SectionTopMenu,
        SnapshotEditDialog,
        SnapshotExportDialog,
        AssetDetailDialog,
        AssetCompareDialog,
        EmptyState
    },
    mixins: [permissionsMixin],
    inheritAttrs: false,
    props: {
        application: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            loading: false,
            snapshots: [],
            columns: [
                {
                    label: 'Snapshot',
                    class: ['w-56 sm:w-48'],
                    component: {
                        is: markRaw(SnapshotName)
                    }
                },
                {
                    label: 'Source',
                    class: ['w-56'],
                    key: '_ownerSortKey',
                    component: {
                        is: markRaw(SnapshotSource)
                    }
                },
                {
                    label: 'Created By',
                    class: ['w-48 hidden md:table-cell'],
                    component: {
                        is: markRaw(UserCell),
                        map: {
                            avatar: 'user.avatar',
                            name: 'user.name',
                            username: 'user.username'
                        }
                    }
                },
                {
                    label: 'Date Created',
                    class: ['w-48 hidden sm:table-cell'],
                    component: { is: markRaw(DaysSince), map: { date: 'createdAt' } }
                }
            ]
        }
    },
    computed: {
        ...mapState('account', ['teamMembership']),
        snapshotList () {
            return this.snapshots.map(s => {
                return {
                    label: s.name,
                    description: s.description || '',
                    value: s.id
                }
            })
        }
    },
    mounted () {
        this.loadSnapshots()
    },
    methods: {
        loadSnapshots: async function () {
            this.loading = true
            const data = await ApplicationApi.getSnapshots(this.application.id, null, null, null)
            this.snapshots = applySystemUserDetails(data.snapshots)
            this.loading = false
        },
        canViewSnapshot: function (row) {
            return this.hasPermission('snapshot:full')
        },
        showViewSnapshotDialog (row) {
            SnapshotsApi.getFullSnapshot(row.id).then((data) => {
                this.$refs.snapshotViewerDialog.show(data)
            }).catch(err => {
                console.error(err)
                Alerts.emit('Failed to get snapshot.', 'warning')
            })
        },
        showCompareSnapshotDialog (snapshot) {
            SnapshotsApi.getFullSnapshot(snapshot.id)
                .then((data) => this.$refs.snapshotCompareDialog.show(data, this.snapshotList))
                .catch(err => {
                    console.error(err)
                    Alerts.emit('Failed to get snapshot.', 'warning')
                })
        },
        showDownloadSnapshotDialog (snapshot) {
            this.$refs.snapshotExportDialog.show(snapshot)
        },
        showEditSnapshotDialog (snapshot) {
            this.$refs.snapshotEditDialog.show(snapshot)
        },
        onSnapshotEdit (snapshot) {
            const index = this.snapshots.findIndex(s => s.id === snapshot.id)
            if (index >= 0) {
                this.snapshots[index].name = snapshot.name
                this.snapshots[index].description = snapshot.description
            }
        },
        async downloadSnapshotPackage (snapshot) {
            const ss = await SnapshotsApi.getSummary(snapshot.id)
            const owner = ss.device || ss.project
            const ownerType = ss.device ? 'device' : 'instance'
            const packageJSON = {
                name: `${owner.safeName || owner.name}`.replace(/[^a-zA-Z0-9-]/g, '-').toLowerCase(),
                description: `${ownerType} snapshot, ${snapshot.name} - ${snapshot.description}`,
                private: true,
                version: '0.0.0-' + snapshot.id,
                dependencies: ss.modules || {}
            }
            downloadData(packageJSON, 'package.json')
        },
        // snapshot actions - delete
        showDeleteSnapshotDialog (snapshot) {
            Dialog.show({
                header: 'Delete Snapshot',
                text: 'Are you sure you want to delete this snapshot?',
                kind: 'danger',
                confirmLabel: 'Delete'
            }, async () => {
                await SnapshotsApi.deleteSnapshot(snapshot.id)
                const index = this.snapshots.indexOf(snapshot)
                this.snapshots.splice(index, 1)
                Alerts.emit('Successfully deleted snapshot.', 'confirmation')
            })
        },
        isDevice: function (row) {
            return row.ownerType === 'device' || !!row.device
        },
        // enable/disable snapshot actions
        canDownload (_row) {
            return this.hasPermission('snapshot:export')
        },
        canDownloadPackage (row) {
            if (this.isDevice(row)) {
                return this.hasPermission('device:snapshot:read')
            }
            return this.hasPermission('project:snapshot:read')
        },
        canDelete (row) {
            if (this.isDevice(row)) {
                return this.hasPermission('device:snapshot:delete')
            }
            return this.hasPermission('project:snapshot:delete')
        }
    }
}
</script>
